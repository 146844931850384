.items-container {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    opacity: 0; /* Initially hide the container */
    transition: opacity 1s ease; /* Smooth transition for opacity */
}

.fade-up {
    opacity: 1; /* Make the container visible */
    animation: fade-up-animation 1s ease;
}

@keyframes fade-up-animation {
    0% {
        transform: translateY(50px); /* Move container downwards */
    }
    100% {
        transform: translateY(0); /* Move container back to its original position */
    }
}
.title-item {
    font-family: Clash Grotesk;
    font-size: 5vw;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-align: left;

    background: linear-gradient(90deg, #FF3BFF 0%, #ECBFBF 38.02%, #5C24FF 75.83%, #D94FD5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: auto;
    margin-top: auto;
}

.subtitle-item {
    font-family: Clash Grotesk;
    font-size: 4em;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: auto;
    margin-top: auto;
}

.text-item {
    width: 35em;
    height: 5em;
    top: 473px;
    left: 502px;
}

.button-wrapper {
    position: relative;
    padding: 1px;
    background: linear-gradient(90deg, #FF3BFF 0%, #ECBFBF 38.02%, #5C24FF 75.83%, #D94FD5 100%);
    border-radius: 100px;
    overflow: hidden;
}

.button-item {
    cursor: pointer;
    width: 15vw;
    height: 7vh;
    border-radius: 100px;
    background-color: black;
    color: white;
    font-family: Chillax;
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 1.2em;
    text-align: center;

    position: relative; /* Required for absolute positioning */
    z-index: 1; /* Ensure it's above the pseudo-element */

    /* Transition for smooth color change */
    transition: border-color 0.3s; /* Add transition for border color */
}

.button-item:hover {
    border: 2px solid; /* Change border color on hover */
    animation: pulse-border 1s infinite alternate; /* Start the pulse animation on hover */
}

@keyframes pulse-border {
    0% {
      
    }
    100% {
        border-color: black; /* End color */
    }
}
