.list-container {
  background-color: rgba(128, 128, 128, 0.429);
  /* Cinza claro com opacidade */
  padding: 20px;
  width: 50vw;
  height: 50vh;
  border-radius: 10px;
  overflow: auto;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  color: white;
  /* Cor do texto */
  border-radius: 5px;
  background-color: rgba(38, 45, 38, 0.655);

}

.list-item div {
  flex: 1;
}

.registered {
  background-color: #14C67A4D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #14C67A;
}

.won {
  background-color: #0a4e304d;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #0a4e30;
}

.error {
  background-color: #FF54544D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #FF5454;
}

.discord {
  background-color: #FF54544D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #FF5454;
}

.wallet {
  background-color: #FF54544D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #FF5454;
}

.invalid {
  background-color: #FF54544D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #FF5454;
}

.pending {
  background-color: #FFD15B4D;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #FFD15B;
}

.premium {
  background-color: #bb860165;
  border-radius: 10px;
  padding: 3px;
  max-width: 9vw;
  color: #bb8601;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 3vw;
  color: rgba(255, 255, 255, 0.635)
}

.middle-title {
  padding-left: 13.5vw;
  padding-right: 10vw;
}

.button-start-container {
  margin-top: -2%;
  margin-left: 47vw;
}

.quantityRaffles {
  margin-top: 1%
}

.filterCentralized {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pagination .page-button {
  color: #FFFFFF;
  background-color: #FF4500;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.pagination .page-button.active {
  background-color: #333333;
  pointer-events: none;
}


.pagination .page-button:disabled {
  color: #CCCCCC;
  background-color: #666666;
  cursor: default;
}


.pagination .page-button:not(:disabled):hover {
  background-color: #FF6347;
}


.pagination .ellipsis {
  margin: 0 5px;
  color: #FFFFFF;
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pagination .page-button {
      padding: 5px 10px;
  }
}