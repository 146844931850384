.homeImg {
  position: absolute;
  top: 55vh;
  width: 100%;
  height: 67vh;
  z-index: -1;
}

.elipse-container {
  display: flex;
  flex-direction: row;
  z-index: -5;
}

.elipse {
  position: relative;
  width: 400px;
  height: 400px;
  margin-left: 5vh;
  border-radius: 50%;
  opacity: 5%;
}

.elipse-left {
  background: #8593E8;
  box-shadow: 0px 0px 100px 80px #8593E8;
}

.elipse-center {
  background: #5D6EF3;
  margin-top: auto;
  margin-bottom: 6vh;
  box-shadow: 0px 0px 100px 80px #5D6EF3;
}

.elipse-right {
  background: #FC4FF6;
  box-shadow: 0px 0px 100px 80px #FC4FF6;
  margin-right: 100px;
}