.informationToken {
    background-color: #333;
    color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 90vh;
    height: 100%;
    max-height: 35vh;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    overflow: auto;
  }

  .informationToken h2, h3 {
    text-align: left;
  }

  .informationToken ol {
    padding-left: 20px;
    list-style-type: none;
  }

  .informationToken li::before {
    content: '• ';
    color: #f90;
  }

  .informationToken li {
    margin-bottom: 10px;
    text-align: left;
  }
