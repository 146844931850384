.Dashboard {
    color: white;
}

.elipse-container-dashboard {
    position: relative;
    width: 40vw;
    height: 40vh;
    margin-left: -15vh;
    border-radius: 50%;
    opacity: 5%;
    margin-top: -35%;
    background: #A1FFCE;
    box-shadow: 0px 0px 100px 80px #A1FFCE;
    z-index: -5;
}

.user-container {
    position: absolute;
    top: 1%;
    left: 92%;
    right: 0;
    max-width: 17vw;
}
.dashboard-body{
    display: flex;
    justify-content: center;
    align-items: center;
}

.square-page {
    width: 60vw; 
    height: 90vh;
    margin-top: -60vh;
    margin-left: 20vh;
    background-color: #222222;
    border-radius: 10px;
}
