.sidebar {
    width: 35vh;
    height: 100vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: initial;

    background-color: #010101;
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: auto;
    margin-top: 10px;
}

.logoImg {
    width: 55px;
    height: 57px;
    margin: auto;
    margin-bottom: 10vh;
    margin-left: 1vw;
    align-items: center;

}

.logo h1 {
    font-family: Chillax;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: left;

    margin-top: -8vh;
    margin-right: 2vh;
}


.menu-sidebar {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    text-align: initial;
    align-items: center;

    padding: 0;
    margin-top: 10vh;
}

.menu-item {
    border-radius: 10px;
    cursor: pointer;
    font-family: Chillax;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: white;
    text-decoration: none;
    margin-left: 0px;
    margin-top: 10px;
    text-align: center;
    padding: 11px;
    min-width: 13vw;
    background-color: #010101;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s; 
}

.menu-item:hover {
    background-color: #FF6D03; 
    transform: scale(1.05); 
    border-radius: 10px;
}

.selected {
    background: #FF6D03;
    border-radius: 10px;
    transition: background-color 0.3s, transform 0.3s; 
}

.divisor{
    margin-top: -7vh;
    width: 13vw;
    margin-right: 1vw;
    margin-left: 1vw;
    top: 97px;
    left: 22px;
    border: 1px;

    border: 1px solid #313131
}

.icon-sidebar{
    height: 2.5vh;
    margin-right: 5%;
    
}