.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.nav-logo {
    font-family: Chillax;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    margin-right: auto; /* Add this to align to the left */
}

.nav-links {
    display: flex;
    align-items: center;
    list-style: none;
}

.nav-links li {
    margin-right: 10px;
}

.nav-links a {
    text-decoration: none;
    padding: 1vh;
    font-family: Clash Grotesk;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.nav-links a:hover {
    color: #FFD700; /* Change color on hover */
}

.social-icons {
    display: flex;
    align-items: center;
}

.social-icons {
    margin-left: auto; /* Move the social icons to the right */
}

.social-icons a {
    padding: 8px;
    transition: transform 0.3s ease;
}

.social-icons a:hover {
    transform: scale(1.2);
}
