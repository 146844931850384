.default-page {
    text-align: center; /* Center align the content */
    font-family: Chillax, sans-serif; /* Use the Chillax font */
    padding: 20px; /* Add padding for spacing */
}

.welcome-image {
    max-width: 20%; /* Set a maximum width for the image */
    height: auto;
    margin: 20px auto; /* Center the image horizontally */
    display: block; /* Make sure the image is centered */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}


.description {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.section-list {
    list-style: none;
    padding: 0;
}

.section-list li {
    margin-bottom: 10px;
}
