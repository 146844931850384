.connect-button {
    cursor: pointer;
    font-family: Chillax;
    width: 7vw;
    height: 30px;
    border-radius: 50px;
    margin-left: 3%;
    margin-top: 0.3%;
    background-color: #3050CE;
    color: white;
    border: none;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s;
}

.connect-button:hover {
    background-color: #1A2E8E;
}

.connect-button.success {
    background-color: #4CAF50; /* Green background for success state */
}

.loader {
    position: absolute;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}
