.raffles-container {
    font-family: Chillax;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #454545;
    width: 17vw;
    border-radius: 10px;
}

.raffle-item {
    font-family: Chillax;
    outline: none;
    background: none;
    padding: 5px 15px;
    border: none;
    color: white;
    width: 10vw;

}

.raffle-item:hover {
    cursor: pointer;
    background: #FF6D03;
    border-radius: 5px;
}
.selected {
    background: #FF6D03;
    border-radius: 5px;
}