.Error {
    height: 100vh; /* Set height to full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any content that overflows the container */
}

.Error img {
    max-width: 100%; /* Limit the image width to the container width */
    max-height: 100%; /* Limit the image height to the container height */
}

.Error button {
    font-size: 16px; /* Set font size */
    padding: 10px 20px; /* Add padding */
    border: none; /* Remove border */
    border-radius: 20px; /* Add border radius */
    background-color: blue; /* Set background color */
    color: white; /* Set text color */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for background color and transform */

    /* Center the button */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Error button:hover {
    background-color: darkblue; /* Change background color on hover */
    transform: scale(1.05); /* Scale up slightly on hover */
}
