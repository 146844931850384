.setting-container {
    font-family: Chillax;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #454545;
    width: 30vw;
    border-radius: 10px;
}

.setting-item {
    font-family: Chillax;
    outline: none;
    background: none;
    padding: 10px 30px;
    border: none;
    color: white;
    width: 100%;

}

.setting-item:hover {
    cursor: pointer;
    background: #FF6D03;
    border-radius: 5px;


}

.selected {
    background: #FF6D03;
    border-radius: 5px;
}